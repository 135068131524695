#calculator {
    .header_wrapper {
        position: relative;
        background: #4fc2ee;
        min-height: 350px;
        margin: 0 -15px;

        &:after {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 100%;
            height: 80px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
        }

        .header {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: url('/img/bg_calculator1.png') center bottom no-repeat;
            padding: 15px;
            color: #FFF;
            z-index: 1;

            h1 {
                text-align: center;
                line-height: 1.2;
                margin: 0;
            }
        }
    }

    aside {
        background: linear-gradient(to bottom, rgba(239, 73, 79, 1) 0%, rgba(240, 59, 49, 1) 100%);
        padding: 30px 60px;

        h2 {
            color: #FFF;
            font-weight: 600;
            margin: 0 0 30px 0;
            font-size: 28px;
        }

        p {
            color: #FFF;
            margin-bottom: 30px;

            img {
                width: 30px;
            }

            &.bad-script {
                font-size: 24px;
            }
        }
    }

    .content {
        padding: 30px 60px;

        h1 {
            color: #CE0B10;
            font-size: 28px;
            margin: 0 0 30px 0;

            img {
                width: 35px;
            }
        }

        h2 {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        form {

            .type_wrapper {
                overflow: hidden;

                .label_type {
                    float: left;
                    text-align: center;
                    margin-right: 20px;
                    width: 80px;

                    img {
                        display: block;
                        height: 46px;
                        margin: 0 auto;
                        margin-bottom: 15px;
                    }

                    input {
                        display: none;
                    }

                    span {
                        display: block;
                        width: 56px;
                        height: 50px;
                        background: url('/img/inputs.png') no-repeat;
                        background-position: 0 -100px;
                        margin: 6px auto;

                        &.checked {
                            background-position: 0 -150px;
                        }
                    }
                }
            }

            input.brand {
                width: 100%;
                max-width: 375px;
                margin-top: 4px;
                padding: 8px 15px;
                font-weight: bold;
                border: 3px solid #bdbcbc;
                background: #e5e5e5;
                margin-bottom: 10px;
            }

            .shape_wrapper {
                overflow: hidden;
                margin-bottom: 30px;

                .shape_wrapper {
                    float: left;

                    .label_shape {
                        float: left;
                        display: block;
                        text-align: center;
                        margin-right: 20px;
                        width: 80px;

                        img {
                            display: block;
                            height: 46px;
                            margin: 0 auto;
                            margin-bottom: 15px;
                        }

                        input {
                            display: none;
                        }

                        span {
                            display: block;
                            width: 56px;
                            height: 50px;
                            background: url('/img/inputs.png') no-repeat;
                            background-position: 0 -100px;
                            margin: 0px auto 6px auto;

                            &.checked {
                                background-position: 0 -150px;
                            }
                        }

                        &.square {
                            span {
                                background-position: 0 0px;

                                &.checked {
                                    background-position: 0 -50px;
                                }
                            }
                        }

                        &.other {
                            span {
                                background-position: 0 -200px;

                                &.checked {
                                    background-position: 0 -250px;
                                }
                            }
                        }
                    }
                }

                .sizes_wrapper {
                    width: 155px;
                    margin-left: 15px;
                    display: none;

                    input {
                        width: 100%;
                        margin-top: 4px;
                        padding: 8px 15px;
                        font-weight: bold;
                        border: 3px solid #bdbcbc;
                        background: #e5e5e5;
                        margin-bottom: 10px;
                    }
                }
            }

            button.button {
                font-weight: bold;
                color: #fff;
                background: #ce0b10;
                border: 0;
                border-radius: 5px;
                padding: 15px;
                width: 200px;
            }

            .noresults_wrapper {
                display: none;
                position: absolute;
                right: 30px;
                bottom: 30px;
                width: 450px;

                img {
                    float: left;
                    margin-right: 15px;
                }

                a {
                    color: #ce0b10;
                }
            }
        }

        #calculatorResults {
            width: 100%;
            display: none;
            margin-top: 60px;

            thead {
                th {
                    border-bottom: 1px solid #CCC;
                }
            }

            tr {
                th,td {
                    padding: 5px 0;
                    font-size: 18px;
                    font-weight: bold;

                    img {
                        width: 110px;
                    }

                    a.button {
                        display: inline-block;
                        color: #fff;
                        background: #ce0b10;
                        border: 0;
                        border-radius: 5px;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}

/**
 * SM
 */
@media (min-width: 768px) {
    #calculator {
        .header_wrapper {
            min-height: 260px;

            .header {
                background: url('/img/bg_calculator1.png') right bottom no-repeat;

                h1 {
                    position: absolute;
                    left: 45px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}

/**
 * MD
 */
@media (min-width: 992px) {
    #calculator {
        .header_wrapper {
            .header {
                background: url('/img/bg_calculator1.png') calc(100% - 90px) bottom no-repeat;

                h1 {
                    left: 90px;
                }
            }
        }

        .content {
            form {
                .noresults_wrapper {
                    width: 250px;
                }
            }
        }
    }
}

/**
 * MD
 */
@media (min-width: 992px) {
    #calculator {
        .header_wrapper {
            .header {
                background: url('/img/bg_calculator1.png') calc(100% - 90px) bottom no-repeat;

                h1 {
                    left: 90px;
                }
            }
        }

        .content {
            form {
                .noresults_wrapper {
                    width: 350px;
                }
            }
        }
    }
}

/**
 * XL
 */
@media (min-width: 1200px) {
    #calculator {
        .header_wrapper {
            .header {
                background: url('/img/bg_calculator1.png') calc(100% - 220px) bottom no-repeat;

                h1 {
                    left: 115px;
                }
            }
        }

        .content {
            form {
                .noresults_wrapper {
                    width: 450px;
                }
            }
        }
    }
}