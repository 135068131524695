#header {
    .language-wrapper {
        position: absolute;
        top: 24px;
        right: 80px;
        z-index: 10000;
        color: #FFF;
        background-color: #3ab1b0;
        padding: 0 10px;

        ul {
            margin-top: 2px;
            padding: 0;
            margin-bottom: 0;
            list-style: none;

            li {
                cursor: pointer;

                p {
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;

                    &.hover {
                        color: #fff;
                    }
                }

                ul {
                    display: none;
                    padding: 0;

                    li {
                        padding: 5px;
                        margin-bottom: 5px;
                        list-style: none;
                        padding-right: 5px;

                        &:hover {
                            background-color: #38a09b;
                        }

                        a {
                            font-weight: 600;
                            color: #FFF;
                        }

                        list-style: none;
                    }
                }

                // &:hover {
                //     ul {
                //         display: block;
                //     }
                // }
            }
        }
    }

    .menu {
        // padding-top: 20px;
    }
}

@media (min-width: 991px) {
    #header {
        .language-wrapper {
            top: 0px;
            right: 0px;
        }
    }
}