@font-face {
    font-family: HelveticaRounded;
    src: url("/css/fonts/HelveticaRoundedLTStd-Bd.otf") format("opentype");
}

#header {
    position: relative;

    &:after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: -40px;
        width: 100%;
        height: 40px;
        z-index: 100;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.titel-header {
    background-image: url(/img/contact-title-block.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 260px;
    h1 {
        color: #fff;
        padding-top: 90px;
    }
}

.contact-header-img {
    background-image: url(/img/contact-vrouw.png);
    background-repeat: no-repeat;
    height: 260px;
    width: 325px;
    margin-right: 30px;
    float: right;
    z-index: 11;
    position: relative;
}

textarea {
    overflow: hidden;
}

.contact-block {
    padding-bottom: 30px;
}

.field-22 .heading label {
    display: none;
}

.file-upload {
    margin-top: 10px;
    margin-bottom: 15px;

    .file-input {
        display: none;
    }

    .file-input-fake {
        width: 100%;
        height: 60px;
        border: 1px dashed #9D9D9D;
        color: #757575;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        background-color: #e5e5e5;
        outline: 0;
    }
}

.contact-block {
    h3 {
        color: #d31820;
        font-size: 22px;
        font-weight: 600;
        padding-bottom: 10px;
        padding-top: 45px;
    }

    input:not([type=checkbox]), textarea {
        font-weight: 600;
        height: 45px;
        margin-bottom: 10px;
        background-color: #e5e5e5;
        border: none;
        padding-left: 15px;
        color: #000;
        border-radius: 0;
        box-shadow: none;

        &::placeholder {
            color: #757575;
        }
    }

    textarea {
        max-height: 100px;
        height: 100px;
    }
}

.Actie .contact-block textarea {
    max-width: 107.6%;
    min-width: 100%;
    resize: none;
    max-height: 100px;
    height: 100px;
    padding-top: 15px;
}

textarea[name="bericht"] {
    height: 100px;
    resize: none;
    padding-top: 15px;
}

.Actie textarea {
    height: 100px;
    padding-top: 20px;
}

.contact-block {
    .btn-primary {
        width: 120px !important;
        height: 45px;
        background-color: #d31820 !important;
        font-size: 12px;
        color: #fff !important;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 23px;
        margin-top: 4px;
        border-radius: 7px !important;
        text-align: center !important;
    }
    .white-arrow input {
        background-image: url(/img/white-arrow-submit.png);
        background-repeat: no-repeat;
        background-position: center right;
    }
}

.contact-message {
    margin-top: 20px;
    margin-bottom: 20px;
}

.over-ons-header {
    background-image: url(/img/over-ons-header.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 260px;
    h1 {
        color: #fff;
        padding-top: 90px;
    }
}

.over-ons-header-img {
    background-image: url(/img/over-ons-man.png);
    background-repeat: no-repeat;
    height: 260px;
    width: 315px;
    margin-right: 30px;
    float: right;
    z-index: 11;
    position: relative;
}

.over-ons-hart {
    background-image: url(/img/over-ons-hart.png);
    background-repeat: no-repeat;
    width: 90%;
    height: 184px;
    margin-right: 10%;
    background-size: contain;
}

.over-ons-tekst {
    background-image: url(/img/over-ons-tekst.png);
    background-repeat: no-repeat;
    width: 90%;
    height: 184px;
}

.over-ons-hart .tekst {
    color: #fff;
    font-size: 26px;
    font-weight: 200;
    width: 270px;
    padding-left: 70px;
    padding-top: 35px;
}

.opgericht-block {
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: #fff;
}

.opgericht-tekst {
    font-size: 28px;
    color: #d31820;
    text-align: center;
    width: 100%;
    padding-top: 45px;
}

.paars-block {
    text-align: center;
    background-color: #8a4e9f;
    color: #fff;
    padding-top: 60px;
    padding-bottom: 32px;
    font-weight: 300;
}

.col-paars-block {
    padding-bottom: 25px;
}

.groen-block {
    background-color: #97d35c;
    color: #fff;
    font-size: 60px;
    text-align: center;
    padding-top: 105px;
    padding-bottom: 100px;
}

.blauw-block {
    background-color: #3adceb;
    color: #fff;
    padding-top: 42px;
    padding-bottom: 85px;
    h3 {
        font-size: 22px;
        margin-left: 90px;
        margin-bottom: 35px;
        font-weight: 600;
    }
}

.familie-Dirk {
    background-image: url(/img/familie-dirk.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #f9cf00;
}

.familie-Doris {
    background-image: url(/img/familie-desi.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #00e0c3;
}

.familie-Dennis {
    background-image: url(/img/familie-dennis.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #00b3e6;
}

.familie-Diana {
    background-image: url(/img/familie-diana.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #00e09f;
}

.familie-Dummie {
    background-image: url(/img/familie-dummie.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #f94600;
    .familie-naam {
        margin-top: -4px;
    }
}

.familie-Daan {
    background-image: url(/img/familie-daan.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #f9006c;
}

.familie-Desi {
    background-image: url(/img/familie-desi.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #00e0c3;
}

.familie-David {
    background-image: url(/img/familie-david.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 148px;
    color: #f9b700;
}

.familie-Dave {
    background-image: url(/img/familie-dave.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 155px;
    color: #e600d5;
}

.familie-Daisy {
    background-image: url(/img/familie-daisy.png);
    background-repeat: no-repeat;
    height: 200px;
    width: 145px;
    color: #9bc31e;
    position: relative;
    top: 15px;
    .familie-inhoud {
        padding-top: 115px;
    }
}

.familie-functie.bad-script {
    font-size: 15px;
}

.familie-naam {
    font-size: 20px;
    text-transform: uppercase;
    font-family: HelveticaRounded;
}

.familie-inhoud {
    text-align: center;
    padding-top: 135px;
}

.familie {
    margin-top: 10px;
}

.familie-col {
    margin-right: 30px;
}

.familie-dirk .familie-inhoud {
    padding-top: 138px;
}

.familie-onder {
    margin-left: 95px;
}

.hier-te-koop-header {
    background-image: url(/img/hier-te-koop-header.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 260px;

    h1 {
        color: #fff;
        padding-top: 80px;
    }
}

.hier-te-koop-header-img {
    background-image: url(/img/hier-te-koop-header-img.png);
    background-repeat: no-repeat;
    height: 260px;
    width: 315px;
    margin-right: 30px;
    float: right;
    z-index: 11;
    position: relative;
}

.verkooppunten-block {
    background-color: #fff;
    h3 {
        color: #d31820;
        font-size: 22px;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
        font-weight: 400;
    }
}

.sponsor {
    display: inline-block;
    position: relative;
    margin-right: 50px;
}

.social {
    h2 {
        font-weight: 700;
        font-size: 22px;
    }
}

@media (max-width: 1200px) {
    .over-ons-hart .tekst {
        font-size: 22px;
        padding-left: 75px;
    }
    .opgericht-tekst {
        padding-top: 20px !important;
    }
    .familie-col {
        margin-right: 25px !important;
    }
    .familie-onder {
        margin-left: 75px !important;
    }
}

@media (max-width: 992px) {
    .contact-block, .titel-header, .opgericht-block, .over-ons-header, .col-paars-block {
    }
    .contact-block input {
        width: 100% !important;
    }
    .opgericht-tekst {
        width: 85% !important;
        padding-top: 15px !important;
    }
    .col-paars-block {
        width: 95%;
    }
    .familie {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .familie-col {
        margin-right: 0px !important;
    }
    .over-ons-hart .tekst {
        font-size: 24px;
        padding-left: 80px;
        padding-top: 35px;
    }
    @media (max-width: 958px) {
        .opgericht-tekst {
            padding-top: 20px !important;
        }
    }
    @media (max-width: 768px) {
        .contact-block textarea {
            width: 100% !important;
        }
        .over-ons-hart {
            position: relative;
            max-width: 240px;
            left: -15px;
            .tekst {
                padding-left: 0px;
                text-align: center;
                padding-top: 25px;
                width: 90%;
                position: relative;
                left: 5%;
            }
        }
    }
    .contact-header-img, .over-ons-header-img, .hier-te-koop-header-img {
        //display: none;
    }
    .contact-block {
        input {
            width: 100% !important;
        }
        textarea {
        }
    }
    .opgericht-tekst {
        width: 95% !important;
        padding-top: 45px !important;
    }
    .over-ons-hart {
        margin-left: auto;
        margin-right: auto;
    }
    .familie-col {
        margin-right: 0px !important;
    }
    .familie {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .familie-onder {
        margin-left: 0px !important;
    }
}

@media (max-width: 1199px) {
    .blauw-block {
        h3 {
            text-align: center;
            margin-left: 0px;
        }
        .over-ons-intro, p {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
    }
}

@media (max-width: 768px) {
    .box-1-1 {
        margin-top: 10px !important;
    }
}

@media (max-width: 991px) {
    #dumil-slider .item .slider-afbeelding {
        min-height: 265px;
    }
}

@media (max-width: 830px) {
    #dumil-slider .item .slider-afbeelding {
        min-height: 265px;
    }
}

@media (max-width: 790px) {
    #dumil-slider .item .slider-afbeelding {
        min-height: 255px;
    }
}

@media (max-width: 490px) {
    #dumil-slider .item .slider-afbeelding {
        min-height: 175px;
    }
}

@media (max-width: 430px) {
    h1 {
        //font-size: 24px;
    }

    #dumil-slider .item .slider-afbeelding {
        min-height: 165px;
    }
}

@media (max-width: 375px) {
    #dumil-slider .item .slider-afbeelding {
        min-height: 155px;
    }
}

@media (max-width: 360px) {
    #dumil-slider .item .slider-afbeelding {
        min-height: 145px;
    }
}

@media (max-width: 320px) {
    #dumil-slider .item .slider-afbeelding {
        min-height: 139px;
    }
}

.borderimage {
    //position: absolute;
    //    //z-index: 5;
    //    //margin: 0 auto;
    //    //display: inline-block;
    //    //padding: 3%;
    float: left;
    margin-top: 70px;
    margin-right: 15px;
}

.text-block-detail {
    padding-left: 20px;
}

.disclaimer-text h2 {
    color: #d31820;
    font-size: 22px;
    font-weight: 600;
    margin-top: 30px;
}

#copyright-box {
    p {
        display: inline-block;
        color: white;
        padding-left: 5px;
        padding-right: 5px;
    }
    a {
        color: white;
    }
}

.categorie-intro {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
}

.box-2-2, .box-2-3, .box-2-4 {
    margin-top: -45px;
}

.verkooppunt {
    height: 180px;
    margin: 2%;
    width: 45%;
}

.verkooppuntimage {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100% !important;
    height: 100% !important;
    background-size: contain !important;
}

@media (min-width: 769px) and (max-width: 993px) {
    #contactFormulier > div:nth-child(6) {
        width: 93%;
    }
}

.contact main {
    min-height: 800px !important;
}

@media (max-width: 992px) {
    .text-block-detail {
        margin-left: 70px;
    }
}

@media (max-width: 769px) {
    .text-block-detail {
        margin-left: 0px;
    }

    .container {
        width: 100%;
    }

    //.tip-trick-block {
    //    .tips-trick-mask {
    //        z-index: 5;
    //        position: relative;
    //        opacity: 1;
    //        -webkit-transition: opacity 0.5s;
    //        transition: opacity 0.5s;
    //    }
    //    .categorie-naam {
    //        top: -290px;
    //        -webkit-transition: top 0.5s;
    //        transition: top 0.5s;
    //    }
    //}
}

@media (min-width: 992px) {
    .text-block-detail {
        margin-right: 40px;
    }

    .product-info-detail img {
        padding-left: 40px;
    }

    .v-center-r {
        display: flex;
        align-items: center;
    }
}

.input ul {
    list-style: none;
    padding: 0;
    color: #d31820;
    font-weight: 600;
}

@media (max-width: 1199px) {
    .over-ons-header-img, .tips-header {
        background-position: center 0;
    }
}

.blauw-block p {
    margin-left: 90px;
    margin-bottom: 35px;
    margin-right: 90px;
}

.contact-block p {
    margin-bottom: 30px;
}

@media (max-width: 992px) {
    .navbar-header {
        float: none;
    }
    .navbar-left, .navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
        > li {
            float: none;
            > a {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
    .collapse.in {
        display: block !important;
    }
    #header {
        overflow: visible;
    }
    .active-menu {
        margin-top: 0px;
        padding-top: 0px;
        background-color: #27adab;
        height: 60px;
    }
    .mobile-item {
        width: 100%;
        float: left;
        margin-bottom: 40px;
    }
    .navbar-toggle {
        margin-top: 20px;
        margin-right: 30px;
        border-color: #fff !important;

        &:focus,
        &:hover {
            background-color: #d21720 !important;
        }
    }
    .icon-bar {
        background-color: #fff !important;
    }
    .footer-menu {
        margin-left: -40px;
    }
}

body {
    font-family: "Open Sans", sans-serif;
    background-image: url('/img/dumil-bg.png');
    line-height: 1.5;
}

h1, h2, h3, h4, h5 {
    line-height: 1.5;
}

h1 {
    font-weight: 700 !important;
}

a {
    text-decoration: none !important;
}

button:focus {
    outline: 0;
}

.shadow {
    z-index: 1;
    bottom: 23px;
}

main {
    background-color: #fff;
    position: relative;
}

.bad-script {
    font-family: "Bad Script", cursive !important;
}

.succes-message {
    display: none;
    color: #fff;
    position: relative;
    top: 30px;
}

.bedankt .subscribe-row {
    max-width: 540px;
    margin-top: 80px;
    background-color: #d21720;
}

.bdnkt a {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    top: 150px;
    color: #d21720;
    font-weight: bolder;
}

.bedankt .rowf {
    margin-top: 150px;
    margin-left: 38px;
}

.subscribe-succes .succes-message {
    display: block !important;
}

.succeed {
    display: none;
    color: #000;
}

.grecaptcha-badge {
}

.contactFormulier-succes .succeed {
    display: block !important;
}

.button-bedankt-bottom {
    position: absolute;
    bottom: 50px;
    left: 15px;
}

.bedankt {
    margin-left: -15px;
    h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        width: 300px;
        text-align: left;
        position: absolute;
        left: 50%;
        margin-left: -150px;
        height: 200px;
        margin-bottom: 200px;
        a {
            text-align: center;
        }
    }
}

#footer {
    position: relative;
    z-index: 3;
    width: 100%;
    background-color: #d21720;
    padding: 35px 0;
}

#copyright-box {
    height: 65px;
    background-color: #991418;
    width: 100%;
    color: #fff;
    text-align: center;
    line-height: 65px;
}

.no-input form {
    display: none;
}

.break-padding {
    padding: 0;
}

#subscribe {
    height: 75px;
}

.g-recaptcha:not(.white-btn) {
    margin-left: 30px;
    margin-top: 5px;
    visibility: visible;
}

.menu {
    height: 110px;
    width: 100%;
    background-color: #3ab1b0;
    border: none !important;
    margin-bottom: 0;
}

.logo-menu {
    margin-top: 10px;
    height: 100px;
    z-index: 99999 !important;
}

.navbar-brand {
    z-index: 99999 !important;
    position: relative;
}

.menu-img {
    width: 56px;
    height: 33px;
    background-image: url('/img/menu-heart.png');
    background-repeat: no-repeat;
    position: relative;
    left: 50%;
    margin-left: -28px;
}

.menu-mask {
    position: relative;
    top: -32px;
    right: 11px;
}

.menu-picture-over-ons,
.menu-picture-about-us, 
.menu-picture-a-propos-de-nous,
.menu-picture-ueber-uns,   {
    width: 60px;
    position: relative;
    top: -13px;
    right: 8px;
}

.menu-picture-onze-producten,
.menu-picture-our-products,
.menu-picture-nos-produits,
.menu-picture-unsere-produkte {
    position: relative;
    top: -9px;
    width: 34px;
    right: 0px;
}

.menu-picture-tips-tricks {
    width: 53px;
    right: 0px;
    position: relative;
    top: -13px;
}

.menu-picture-hier-te-koop {
    width: 32px;
    right: 0px;
    position: relative;
    top: -13px;
}

.menu-picture-matchmaker {
    width: 60px;
    right: 5px;
    position: relative;
    top: -7px;
}

.menu-picture-contact,
.menu-picture-contacter,
.menu-picture-kontakt,
.menu-picture-actie {
    width: 41px;
    right: 0px;
    position: relative;
    top: -13px;
}

.menu-item {
    color: #fff;
    width: 150px;
    text-align: center;

    a {
        color: #FFF !important;
        padding: 0 !important;
    }

    span {
        font-weight: 600;
        position: relative;
        top: 5px;
    }

    .active-menu {
        height: 110px;
        margin-top: -37px;
        padding-top: 37px !important;
        background-color: #fff;

        span {
            color: #bf2533 !important;
        }
        .menu-img {
            width: 60px;
            background-image: url('/img/menu-red-heart.png') !important;
        }
    }
}

.menu-items {
    margin-top: 37px;
}

.shadow {
    width: 100%;
    position: relative;
    bottom: 20px;
}

.item {
    float: left;
    width: 100%;
}

.level-2 {
    height: 388px;
}

.level-3 {
    height: 340px;
}

.level-4 {
    //height: 175px;
    background-color: #FFF;
    padding-left: 15px;
    padding-right: 15px;
}

.navbar-nav > a {
    float: left;
}

.nav > a {
    position: relative;
    display: block;
}

.actie-content {
    padding-top: 50px;
}

.actie-wrapper h2 {
    background: none;
    font-weight: 600;
}

.actie-wrapper h5 {
    font-size: 16px;
    line-height: 1.5;
}

.actie-titel {
    color: #d21720;
}

.social-sub-block {
    padding-bottom: 50px;
}

.geen-actie {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.box-1-1 {
}

.header-img {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center right;
    right: 0px;
    width: 680px;
    height: 388px;
    background-image: url('/img/header-persons.png');
    background-size: 90%;
    top: 5px;
}

.box-1-text {
    padding-top: 15px;
    color: #d31820;
    width: 380px;
    max-width: 380px;
    p {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 600;
    }
    h1 {
        margin-top: 25px;
        line-height: 59px;
        font-size: 48px;
    }
}

.box-2-1 {
    background-image: url(/img/over-ons-header.png);
    width: 100%;
    height: 388px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center top;
    padding: 20px;
    .white-btn {
        top: 60px;
    }
}

.box-2-text {
    color: #fff;
    margin-top: 55px;
    p {
        font-size: 16px;
        font-weight: 600;
        width: 400px;
        margin: 0 auto;
        margin-top: 40px;
    }
    h2 {
        font-weight: 400;
        font-size: 50px;
        position: relative;
        top: 15px;
        text-align: center;
        -ms-transform: rotate(-2deg);
        -webkit-transform: rotate(-2deg);
        transform: rotate(-2deg);
    }
}

.box-2-2 {
    background-image: url('/img/box-2-v2.png');
    width: 100%;
    height: 388px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center center;
    -webkit-transition: top 0.5s, margin-top 0.5s;
    transition: top 0.5s, margin-top 0.5s;
    .box-2-text-hover p {
        line-height: 20px;
    }
    .white-btn {
        padding: 14px;
    }
}

.box-2-3 {
    background-image: url('/img/box-2-3.png');
    width: 100%;
    height: 388px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center top;
    -webkit-transition: top 0.5s, margin-top 0.5s;
    transition: top 0.5s, margin-top 0.5s;
    .box-2-text-hover p {
        line-height: 20px;
    }
    .white-btn {
        padding: 14px;
    }
}

.box-2-4 {
    background-image: url('/img/contact-vrouw.png');
    width: 100%;
    height: 388px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center top;
    -webkit-transition: top 0.5s, margin-top 0.5s;
    transition: top 0.5s, margin-top 0.5s;
    .box-2-text-hover p {
        line-height: 20px;
    }
    .white-btn {
        padding: 14px;
    }
}

.box-3-1 {
    background-image: url('/img/box-3-1.png');
    width: 100%;
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center left;
}

.box-3-text {
    float: right;
    color: #fff;
    padding: 25px;
    z-index: 3;
    position: relative;
    text-align: right;
    width: 220px;
}

.box-3-1 .box-3-text {
    .box-btn {
        .btn-text {
            font-size: 10px !important;
        }
        .btn-arrow {
            height: 11px;
            width: 6px;
            margin-top: 0px;
        }
    }
    .red-btn {
        padding: 8px !important;
        padding-bottom: 10px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}

.box-3-text {
    h4 {
        font-size: 22px;
        font-weight: 700;
    }
    p {
        margin: 30px 0;
    }
}

.box-3-2 {
    background-image: url('/img/box-3-2.png');
    width: 100%;
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: bottom right;
    z-index: 3;
    position: relative;
}

.text-32,
.text-33 {
    float: none !important;
    text-align: left;
    width: 100%;
}

.text-33 {
    width: 210px;
}

.box-3-text h3 {
    margin-top: 10px;
    font-weight: 700;
}

.box-3-2 {
    .box-btn {
        .btn-text {
            font-size: 10px !important;
        }
        .btn-arrow {
            height: 11px;
            width: 6px;
            margin-top: 0px;
        }
    }
    .white-btn {
        padding: 8px !important;
        padding-bottom: 10px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}

.btn-spot {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 3;
}

.btn-contact {
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 3;
}

.box-3-3 {
    background-color: #fff;
    background-image: url('/img/contact-vrouw.png');
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: right center;
    z-index: 3;
    position: relative;
}

.text-33 {
    color: #894e9e;
}

.box-3-3 {
    .box-btn {
        .btn-text {
            font-size: 10px !important;
        }
        .btn-arrow {
            height: 11px;
            width: 6px;
            margin-top: 0px;
        }
    }
    .purple-btn {
        padding: 8px !important;
        padding-bottom: 10px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}

.box-heart {
    background-image: url('/img/heart.png');
    width: 154px;
    height: 91px;
    background-size: cover;
    background-position: center center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    margin-top: 80px;
    left: 50%;
    margin-left: -78px;
    z-index: 3;
    -webkit-transition: top 0.5s, margin-top 0.5s;
    transition: top 0.5s, margin-top 0.5s;

    .white-btn {
        display: none;

        &.hover-on {
            display: initial;
        }
    }

    &.french {
        p {
            line-height: 25px;
            margin-top: 20px;

            &.hover-on {
                margin-top: unset;
                line-height: 78px;
            }
        }
    }

    &.big {
        width: 258px;
        height: 150px;
        margin-top: -75px;
        margin-left: -129px;
        background-size: cover;
    }
    p {
        line-height: 78px;
        color: #d21720;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        -webkit-transition: font-size 0.5s, line-height 0.5s;
        transition: font-size 0.5s, line-height 0.5s;
    }
    &.big p {
        font-size: 30px;
        line-height: 129px;
    }
}

.social-icon {
    width: 65px;
    position: absolute;
    right: 40px;
    height: 65px;
    top: 55px;
}

.social-blok span {
    color: #fff;
    line-height: 115px;
    padding-left: 45px;
    font-size: 21px;
    font-weight: 600;
}

.first-word span {
    font-weight: 700;
}

.fix-it {
    width: 100%;
    margin: 0 auto;
}

@-moz-document url-prefix() {
    .field-22 .heading label {
        padding-left: 15px;
    }
}

.button-spot {
    margin-top: 0px;
    text-align: center;
}

.white-btn {
    background-color: #fff;
    color: #d21720;
    border: none;
    border-radius: 7px;
    padding: 12px;
}

.red-btn {
    background-color: #d21720;
    color: #fff;
    border: none;
    border-radius: 7px;
    padding: 12px;
}

.purple-btn {
    background-color: #894e9e;
    color: #fff;
    border: none;
    border-radius: 7px;
    padding: 12px;
}

.btn-text {
    font-size: 12px;
    font-weight: 600;
}

.btn-arrow {
    margin-top: -3px;
    margin-left: 5px;
    width: 9px;
    height: 14px;
}

.social-blok {
    width: 100%;
    height: 115px;
    margin-top: 30px;
}

.facebook {
    background-color: #3a5897;
}

.pinterest {
    background-color: #cc2027;
}

.twitter {
    background-color: #54aced;
}

.footer-menu {
    color: #fff;
    list-style: none;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    padding: 0;
    line-height: 30px;
}

#footer a {
    color: #fff;
}

.social-small {
    display: inline-block;
    margin-top: 30px;
    width: 50px;
    margin: 30px 5px 0 5px;
}

.email .input {
    .parsley-errors-list {
        color: #fff;
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
    .parsley-required, .parsley-type {
        color: #fff;
        list-style: none;
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 5px;
        margin-left: -170px;
    }
}

@media (max-width: 766px) {
    .email .input {
        .parsley-required, .parsley-type {
            margin: 0;
            padding-top: 10px;
        }
    }
}

.subscribe-row {
    margin-top: 40px;
    border-top: 2px solid #eea9ac;
    border-bottom: 2px solid #eea9ac;
    height: 80px;
}

.part-1 {
    color: #fff;
    font-weight: 600;
    line-height: 75px;
    position: absolute;
    font-size: 16px;
}

.part-2 {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 50%;
    margin-left: -115px;
    margin-top: 15px;
    height: 44px;
    input {
        border: none;
        background-color: #a1151d;
        color: #fff;
        padding-left: 20px;
        &::-webkit-input-placeholder {
            padding-left: 0px;
            color: #fff;
        }
    }
}

input:focus {
    outline: 0;
}

.email {
    input.text {
        width: 320px;
        height: 44px;
        margin-left: 20px;
    }
    label {
        display: none;
    }
}

.part-3 {
    margin-top: 15px;
    float: right;
}

#copyright-box p {
    margin-bottom: -10px;
    font-size: 12px;
}

.mask {
    width: 100%;
    height: 100%;
    background-color: rgba(18, 156, 189, 0.8);
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.over-hover:hover .mask {
    opacity: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
    z-index: 0;
}

.box-2-1:hover .mask {
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}

.over-hover {
}

.box-2-1 {
    .box-heart {
        -webkit-transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
        transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
        z-index: 0;
    }
    &:hover .box-heart {
        top: 60px;
        margin-top: 0px;
        -webkit-transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s, z-index 0.5s;
        transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
        width: 300px;
        height: 44%;
        margin-left: -145px;
        z-index: 0;
        p {
            -webkit-transition: font-size 0.5s, line-height 0.5s;
            transition: font-size 0.5s, line-height 0.5s;
            font-size: 20px;
            line-height: 158px;
        }
    }
}

.box-2-2 {
    .box-heart {
        -webkit-transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
        transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
        z-index: 200;
    }
    &:hover {
        .box-heart {
            top: 60px;
            margin-top: 0px;
            -webkit-transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
            transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
            width: 116px;
            height: 67px;
            margin-left: -58px;
            p {
                -webkit-transition: font-size 0.5s, line-height 0.5s;
                transition: font-size 0.5s, line-height 0.5s;
                font-size: 13px;
                line-height: 58px;
            }
        }
        .background-b-2-2 {
            opacity: 1;
            -webkit-transition: opacity 0.8s;
            transition: opacity 0.8s;
        }
    }
}

.background-b-2-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(170, 22, 27, 0.8);
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    top: 0px;
}

.box-2-text-hover {
    width: 180px;
    text-align: center;
    position: relative;
    margin: 0 auto;
    top: 100%;
    color: #fff;
    font-weight: 200;
    font-size: 12px;
    -webkit-transition: top 0.7s, margin-top 0.7s;
    transition: top 0.7s, margin-top 0.7s;
}

.box-2-2:hover .box-2-text-hover {
    top: 205px;
    z-index: 2;
    -webkit-transition: top 0.7s, margin-top 0.7s;
    transition: top 0.7s, margin-top 0.7s;
}

.fix-button-spot {
    margin-top: 45px;
    z-index: 2;
}

.box-2-3, .box-2-4 {
    .box-heart {
        -webkit-transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
        transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
        z-index: 200;
    }
    &:hover {
        .box-heart {
            top: 60px;
            margin-top: 0px;
            -webkit-transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
            transition: top 0.5s, margin-top 0.5s, margin-left 0.5s, width 0.5s, height 0.5s;
            width: 116px;
            height: 67px;
            margin-left: -58px;
            font-size: 16px;
            p {
                -webkit-transition: font-size 0.5s, line-height 0.5s;
                transition: font-size 0.5s, line-height 0.5s;
                font-size: 13px;
                line-height: 58px;
            }
        }
        .background-b-2-3 {
            opacity: 1;
            -webkit-transition: opacity 0.8s;
            transition: opacity 0.8s;
        }
    }
}

.background-b-2-3 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(108, 147, 57, 0.8);
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    top: 0px;
}

.box-2-text-hover {
    width: 180px;
    text-align: center;
    position: relative;
    margin: 0 auto;
    top: 120%;
    color: #fff;
    font-weight: 200;
    font-size: 12px;
    -webkit-transition: top 0.7s, margin-top 0.7s;
    transition: top 0.7s, margin-top 0.7s;
}

.box-2-3:hover .box-2-text-hover {
    top: 205px;
    z-index: 2;
    -webkit-transition: top 0.7s, margin-top 0.7s;
    transition: top 0.7s, margin-top 0.7s;
}

.box-2-4:hover .box-2-text-hover {
    top: 205px;
    z-index: 2;
    -webkit-transition: top 0.7s, margin-top 0.7s;
    transition: top 0.7s, margin-top 0.7s;
}

.box-3-1 {
    .level-3-mask {
        height: 100%;
        width: 100%;
        background-color: rgba(255, 149, 45, 0.7);
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }
    &:hover .level-3-mask {
        opacity: 1;
        -webkit-transition: opacity 0.8s;
        transition: opacity 0.8s;
    }
}

.box-3-2 {
    .level-3-2-mask {
        background-color: rgba(58, 177, 176, 0.7);
        height: 100%;
        position: absolute;
        top: 0px;
        width: 100%;
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }
    &:hover .level-3-2-mask {
        opacity: 1;
        -webkit-transition: opacity 0.8s;
        transition: opacity 0.8s;
    }
}

.box-3-3 {
    .level-3-3-mask {
        background-color: rgba(255, 255, 255, 0.7);
        height: 100%;
        position: absolute;
        top: 0px;
        width: 100%;
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }
    &:hover .level-3-3-mask {
        opacity: 1;
        -webkit-transition: opacity 0.8s;
        transition: opacity 0.8s;
    }
}

#producten {
    overflow-x: hidden;
}

.product-header {
    width: 100%;
    height: 260px;
    background-image: url('/uploads/afbeeldingen/pHeader.png');
}

.product-titel h1 {
    font-weight: 600;
    color: #fff;
    //line-height: 260px;
    padding: 0px;
    margin: 0 auto;
    padding-left: 95px;
}

.tab {
    height: 70px;
    width: 100%;
    color: #fff;
    cursor: pointer;

    h2 {
        padding: 0px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 70px;
        text-align: center;
        font-weight: bold;
    }
}



.tab_wrapper:nth-of-type(1) .tab {
    background-color: #d31820;
}

.tab_wrapper:nth-of-type(2) .tab {
    background-color: #3adceb;
}

.tab_wrapper:nth-of-type(3) .tab {
    background-color: #97d35c;
}

.tab_wrapper:nth-of-type(4) .tab {
    background-color: #ff962d;
}




.product-view {
    overflow: hidden;
    height: 140px !important;
    //padding-left: 10px;
    width: 100%;
    background-color: #fff;
    color: #d2171f;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: #3ab2b1;
    }

    h4 {
        margin: 0 auto;
        padding-top: 20px;
        padding-left: 15px;
        font-size: 18px;
        font-weight: 600;
    }

    .image {
        float: left;
        width: 30%;
        //padding: 0px 0 0 10px;

        img {
            width: 100%;
        }
    }

    .content {
        float: left;
        width: 70%;
        padding-right: 15px;
    }
}

.product-text-box {
    bottom: 38px;
    left: 30px;
    color: #000;
    font-size: 14px;
    //width: 75%;
    bottom: 50px;
    padding-left: 15px;
    //margin-top: 10px;
}

.product-view:hover .product-text-box {
    color: #fff;
}

.product-active {
    color: #fff;
    background-color: #3ab2b1;
    .product-text-box {
        color: #fff;
    }
}

.overzichtsimg {
    max-width: 70%;
    margin-left: 15%;
    margin-top: 50px;
    img {
        width: 70%;
        height: 70%;
    }
}

.product-detail {
    display: none;
    width: 100%;
    //height: 415px;
    color: #d2171f;
    background-image: url('/img/yellow-block.png');
    background-size: cover;
    overflow: hidden;
    a {
        color: #d2171f;
    }
}

.arrow {
    display: none;
}

.arrow-active {
    display: block;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -21px;
    z-index: 3;
    margin-top: -2px;
}

.only-if-click {
    display: none;
}

.product-detail-container .product-detail {
    display: block !important;
}

.text-block-detail {
    margin-top: 10%;
}

.close-x {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 40px;
    z-index: 5;
}

.text-block-detail {
    h2 {
        font-size: 22px;
        font-weight: 600;
    }
    h5 {
        font-size: 17px;
        font-weight: 600;
        line-height: 25px;
        margin-top: 25px;
        margin-bottom: 17px;
    }
    p {
        font-size: 12px;
        margin-top: 15px;
        font-weight: 600;
    }
}

.producten {
    display: none;
}

.active-product {
    display: block;
}

.pijl {
    img {
        display: none;
        width: 40px;
        height: 20px;
        position: relative;
        z-index: 4;
        left: 50%;
        margin-left: -20px;
        margin-top: -2px;
    }
    &.active-pijl img {
        display: block;
    }
}

.product-info-detail img {
    margin-top: 15px;
    margin-left: 0px;
}

.left-top {
    span {
        position: absolute;
        top: 60px;
    }
    img {
        position: absolute;
        top: 90px;
        left: 90px;
    }
}

.left-bottom {
    span {
        position: absolute;
        top: 300px;
    }
    img {
        position: absolute;
        top: 240px;
        left: 40px;
    }
}

.right-top {
    span {
        position: absolute;
        top: 60px;
        right: 0px;
    }
    img {
        position: absolute;
        top: 90px;
        right: 55px;
    }
}

.contact-block .form-group > label {
    display: none;
}

.white-arrow {
    margin-top: 7px;
}

.verkooppunten-block {
    padding-bottom: 35px;

    p {
        line-height: 25px;
    }

    h3 {
        font-weight: 600;
        padding-bottom: 70px;
    }
}

.sponsor-rechts {
    float: right;
}

.sponsor {
    margin-bottom: 70px;
}

.tips-header {
    background-image: url('/img/header-tips.png');
    width: 100%;
    height: 260px;
    background-repeat: no-repeat;
}

.tips-titel {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    h1 {
        font-weight: 600;
        color: #fff;
        padding: 0px;
        margin: 0 auto;
        padding-left: 95px;
        margin-top: -30px;
    }
}

#sortQuery {
    border: none;
    background-color: #e98b8f;
    position: absolute;
    bottom: 23px;
    right: 30px;
    max-width: 125px !important;
    font-size: 22px;
    option {
        font-size: 22px;
    }
    &:focus {
        outline: 0;
    }
}

.waar-te-koop {
    margin-top: -20px;
    background-color: #3ab2b1;
    height: 430px;
    color: #fff;
}

.text-field {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    h3 {
        padding-top: 110px;
        font-size: 28px;
        font-weight: 700;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 15px;
    }
}

.text-te-koop {
    margin-top: 25px;
}

.btn-loc {
    text-align: center;
    margin-top: 45px;
}

.waar-te-koop .box-heart {
    top: 60px;
    margin-top: 0px;
}

.filters .tab {
    height: 80px;
}

.tab {
    h3 {
        line-height: 80px;
        padding: 0px;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 400;
    }
    &.filter {
        background-color: #e5e5e5;
        h3 {
            text-align: center;
        }
    }
    &.categorie {
        background-color: #fff;
        h3 {
            padding-left: 30px;
            color: #e98b8f;
        }
    }
    &.sorteer {
        background-color: #e98b8f;
        h3 {
            padding-left: 30px;
        }
    }
}

.tip-blok {
    background-color: #bfbfbf;
    height: 525px;
    width: 100%;
}

.entry-row {
    padding-top: 50px;
}

.blok-content {
    color: #fff;
    padding-left: 15px;
    h4 {
        font-weight: 600;
        padding-left: 50px;
        padding-top: 50px;
        font-size: 22px;
    }
    ul {
        padding-left: 70px;
        padding-top: 10px;
        font-weight: 600;
    }
}

.categorie-list span {
    font-size: 16px;
    padding-left: 0px;
    position: relative;
    left: 17px;
    top: 0px;
}

.categorie-item {
    visibility: hidden;
}

.product-item {
    visibility: hidden;
    position: relative;
    left: -20px;
    top: 20px;
    font-size: 12px;
}

.product-listing {
    cursor: pointer;
    list-style: none;
}

.attached {
    margin-top: -10px;
}

.sorting-menu {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #e98b8f;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.tip-trick-block {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 525px;
}

.color-block {
    position: absolute;
    bottom: 35px;
    height: 175px;
    width: 100%;
    h2 {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding-left: 15px;
        text-align: center;
        padding-right: 15px;
    }
}

.categorie-naam {
    width: 90px;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    line-height: 40px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: -20px;
    margin-left: -45px;
    -webkit-transition: top 0.5s;
    transition: top 0.5s;
    z-index: 6;
}

.social-shares {
    z-index: 6;
    position: absolute;
    width: 216px;
    line-height: 36px;
    height: 36px;
    text-align: left;
    margin: 0 auto;
    left: 50%;
    margin-left: -109px;
    padding-left: 10px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    bottom: -18px;
    font-weight: 700;

    span {
        float: left;
    }
}

.p {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 30px;
}

.first-in-row {
    padding-left: 15px;
}

.small-social {
    float: right;
    margin-top: 6px;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    position: relative;
    background-repeat: no-repeat;
    //top: -36px;
}

.fb {
    background-image: url('/img/small-fb.png');
}

.pin {
    background-image: url('/img/small-pin.png');
}

.ytb {
    background-image: url('/img/small-ytb.png');
}

.tweet {
    background-image: url('/img/small-tweet.png');
}

.tips-tricks-cards {
}

.filters {
    padding: 0;
}

.tips-trick-mask {
    color: #fff !important;
    width: 100%;
    height: 525px;
    opacity: 0;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.categorie-list {
    position: absolute;
    top: 25px;
    color: #e98b8f;
    left: 150px;
    font-size: 22px;
}

.category-listing {
    padding-left: 25px !important;
    font-weight: 600;
}

.tips-trick-mask h2 {
    margin: 0 auto;
}

.btn-loc .btn-text {
    padding-left: 10px;
    padding-right: 10px;
}

.mask-text {
    width: 90%;
    margin-left: 5%;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    h2 {
        padding-bottom: 15px;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    p {
        font-size: 12px;
    }
}

.tip-trick-block:hover {
    .categorie-naam {
        top: -290px;
        -webkit-transition: top 0.5s;
        transition: top 0.5s;
    }
    .tips-trick-mask {
        z-index: 5;
        position: relative;
        opacity: 1;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }
}

.kleur-is-cyan {
    color: rgb(58, 220, 235);
    background-color: rgba(58, 220, 235, 0.9);
}

.kleur-is-paars {
    background-color: rgba(153, 102, 172, 0.9);
    color: rgb(153, 102, 172);
}

.kleur-is-groen {
    background-color: rgba(150, 210, 92, 0.9);
    color: rgb(150, 210, 92);
}

.kleur-is-roze {
    background-color: rgba(247, 168, 207, 0.9);
    color: rgb(247, 168, 207);
}

.kleur-is-rood {
    background-color: rgba(210, 23, 31, 0.9);
    color: rgb(210, 23, 31);
}

.kleur-is-oranje {
    background-color: rgba(255, 149, 44, 0.9);
    color: rgb(255, 149, 44);
}

.kleur-is-blauw {
    background-color: rgba(0, 159, 226, 0.9);
    color: rgb(0, 159, 226);
}

.chooseProduct, .chooseCategorie {
    display: none;
}

.fixing {
    padding-right: 30px;
}

.fixing-2 {
    padding-right: 45px;
}

.disclaimer-area {
    padding-top: 20px;
    padding-bottom: 40px;
}

@media (min-width: 992px) {
    .actie-offs {
        position: relative;
    }
}

@media (max-width: 1200px) {

    .menu-item {
        width: 125px;

        span {
            font-size: 13px;
        }
    }

    .bedankt.contact {
        padding-bottom: 30px;
    }

    .tab.bereiden h2 {
        padding-right: 0px;
    }
    .first-in-row {
        padding-left: 0px;
    }
    .categorie-list {
        top: 33px;
        font-size: 16px;
        position: absolute;
    }
    .box-1-text {
        padding-top: 25px;
        color: #d31820;
        margin-right: 40px;
        margin-left: -45px;
    }
    .header-img {
        right: 0px;
        background-size: 520px;
    }
    .subscribe-row {
        height: 290px;
        margin-bottom: 50px;
    }
    .part-1 {
        text-align: center;
        width: 100%;
        position: static;
    }
    .part-2 {
        width: 100%;
        position: static;
        left: 0;
        margin-left: 0px;
    }
    .part-3 {
        margin-top: 90px;
        text-align: center;
        margin-bottom: 30px;
        float: none;
    }
    .g-recaptcha:not(.white-btn) {
        margin-left: 23px;
        margin-top: 5px;
        visibility: visible;
    }
    .box-3-1 .level-3-mask, .box-3-2 .level-3-2-mask, .box-3-3 .level-3-3-mask {
        opacity: 1;
    }

    .box-3-text {
        width: 100%;
        text-align: center;

        .text-32,
        .text-33 {
            float: none !important;
            width: 250px;
        }
    }

    .btn-spot,
    .btn-contact {
        left: 50%;
        transform: translate(-50%, 0);
    }

    .social-blok span {
        display: none;
    }
    .social-icon {
        width: 66px;
        height: 66px;
        left: 50%;
        margin-left: -33px;
    }
    .sortQuery {
        max-width: 100px;
    }
    .product-info-detail img {
        margin-top: 15px;
    }
    .active-menu {
        margin-top: -13px;
        padding-top: 15px;
    }
    .Producten #content {
        margin-top: -60px !important;
    }
}

@media (max-width: 992px) {
    .menu {
        // height: 60px;

        .logo-menu {
            margin-top: 0px;
            height: 50px;
        }
    }

    .navbar-collapse {
        box-shadow: none;
    }
    #navbar {
        border: none;
    }

    #navbar ul {
        background-color: #3ab1b0;
    }
    .nav > a {
        height: 47px;
    }
    .navbar-collapse.in {
        overflow-y: visible !important;
    }
    .nav-producten {
        padding-right: 30px;
    }

    .actie-content {
        padding-top: 25px;
        padding-bottom: 30px;
    }
    .nav-producten {
        padding-right: 0px;
    }
    .tab.bereiden h2 {
        padding-left: 20px;
        padding-right: 15px;
    }
    .categorie-list {
        top: 33px;
        font-size: 14px;
    }
    .break-margin-responsive {
        margin: 0 auto;
    }
    .menu-items {
        margin-top: 4px;
        position: absolute;
        width: 100%;
        padding: 40px 30px 0 30px;

        .menu-item {
            width: 100%;
            margin-bottom: 30px;
            text-align: left;

            a {
                &.active-menu {
                    height: auto;
                    margin: -20px -30px -10px -30px;
                    padding: 20px 30px 10px 30px !important;
                }

                .menu-img {
                    text-align: center;
                    position: static;
                    margin-left: 0;
                    left: auto;
                    float: left;
                }

                span {
                    float: left;
                    margin-left: 20px;
                }
            }
        }
    }
    .header-img {
        width: 400px;
        height: 331px;
        right: 0px;
        background-image: url('/img/header-persons-sm.png');
        top: 53px;
        background-size: 90%;
    }
    .box-1-text {
        width: 98%;
        margin-left: 20px;
        padding-top: 20px;
        h1 {
            line-height: 40px;
            font-size: 38px;
        }
        p {
            font-size: 16px;
        }
    }
    .level-2, .level-3, .level-4 {
        height: auto;
    }
    .social-blok {
        margin-bottom: 30px;
    }

    .footer-logo {
        position: relative;
        left: 50%;
        margin-left: -85px;
        margin-bottom: 30px;
    }

    .box-2-text-hover {
        z-index: -2;
        top: 415px;
    }
    .box-heart {
        top: 200px;
    }
    .background-b-2-2, .background-b-2-3 {
        opacity: 1;
    }
    .sorteer h3 {
        visibility: hidden;
    }
    .fixing {
        padding-right: 0px;
    }
    .actie-content {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .actie-b .social-blok {
        height: 115px;
        margin-bottom: 0px;
    }
    .head {
        display: none;
    }
    .chooseProduct, .chooseCategorie {
        display: block;
    }
    .tip-blok {
        height: auto;
    }
    .product-blok {
        display: none;
    }
    .category-listing, .chooseCategorie {
        padding-left: 30px;
        list-style: none;
    }
    .mobile-filters {
        text-align: center;
        height: 70px;
        background-color: #3ab1b0;
        color: #fff;
    }
    .mobiile-listing li {
        list-style: none;
    }
    .mobile-filters h3 {
        line-height: 70px;
        margin: 0 auto;
    }
    #collapseFilter {
        border-top: 1px solid #e3e3e3;
        height: 360px;
        background-color: #3ab1b0;
        color: #fff;
    }
    .filters .tab {
        height: 160px;
    }
    .categorie-list span {
        font-size: 16px;
        padding-left: 0px;
        position: relative;
        left: 17px;
        top: 20px;
    }
    .tip-blok {
        background-color: #3ab1b0;
        h3 {
            padding-top: 10px;
        }
    }
    .blok-content ul {
        padding-left: 20px;
        padding-top: -4px;
        font-weight: 600;
        margin-top: -20px;
    }
    .mobile-listing {
        span {
            margin-left: -35px;
        }
        li {
            margin-left: -25px;
            margin-top: -15px;
            padding-top: 15px;
            position: relative;
            top: -20px;
        }
    }
    .hier-te-koop-header {
        position: relative;

        .row {
            height: 100%;

            [class^=col] {
                height: 100%;

                h1 {
                    padding-top: 0px;
                    margin: 0;
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }

    }
    .sorteer h3 {
        visibility: visible;
    }
    #sortQuery {
        max-width: 125px;
        bottom: 62px;
        left: 120px;
    }
    .hier-te-koop-header-img {
        //display: none;
    }
    .sponsor-rechts {
        float: none;
    }
    .sponsor {
        margin: 0 auto;
        width: 100%;
        margin-bottom: 50px;
    }
    .mobile-sponsor {
        margin: 0 auto;
        max-width: 300px;
    }
    .left-top {
        left: 0px;
        position: absolute;
        top: 15px;
        li {
            margin-left: -10px;
        }
    }

    .product-titel h1 {
        padding-left: 15px;
        margin-bottom: 110px;
    }
    .product-view {
        height: 120px !important;
    }
    .product-detail {
        //height: 650px;
    }
    .product-info-detail img {
        //margin-top: 0px;
        //width: 50%;
        //margin-left: 25%;
    }
    .box-1-1 {
        margin-top: 50px;
    }
    .box-2-text-hover {
        width: 90%;
    }
    .box-2-text {
        p {
            width: 90%;
            margin-top: 85px;
            font-size: 13px;
            opacity: 1 !important;
        }
        margin-top: -15px;
    }
    .bedankt .social-blok {
        height: 50px;
        margin-top: 35px;
    }
    .social-icon {
        width: 40px;
        height: 40px;
        left: 50%;
        margin-left: -20px;
        margin-top: -5px;
    }
    .btn-text {
        font-size: 10px;
    }
    .email input.text {
        width: 90%;
    }
    .social-icon {
        top: 75px;
    }
    .social-small {
        display: inline-block;
        width: 50px;
    }
    .header-img {
        width: 300px;
        height: 171px;
        right: 10px;
        background-image: url('/img/header-persons-ipad.png');
    }
    .email input.text {
        margin-left: 0px;
    }
    .shadow {
        z-index: 1;
        bottom: 25px;
    }
    .tips-titel h1 {
        padding-left: 0px;
    }
    .categorie-list {
        left: 125px;
        list-style: none;
        top: 12px;
    }
    .tab h3 {
        line-height: 160px;
    }
    .filters .tab {
        height: 160px;
    }
    #content.overflow-x {
        overflow: visible;
    }
    .background-b-2-2, .background-b-2-3 {
        opacity: 0;
    }
    .navbar-collapse.in {
        overflow-y: visible !important;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .tab.sorteer h3 {
        visibility: hidden;
    }
    #sortQuery {
        right: 25%;
    }
}

@media (min-width: 768px) and (max-width: 936px) {
    .tab.bereiden h2 {
        padding-left: 20px;
        line-height: 22px;
        padding-top: 14px;
    }
    .bereiden .pijl img {
        margin-top: 12px;
    }
}

.active {
    visibility: visible !important;
}

.tipTrickBlock {
    display: none;
}

.ct-Leuk, .ct-Lekker, .ct-Handig, .ct-BBQ, .ct-Kliekjes {
    display: block;
}

.typeNone {
    display: none;
}

#navbar {
    background-color: #3ab1b0;
    width: 100% !important;
    z-index: 99;
}

.product-row {
    background-color: #Fff;
}

.container {
    background-color: #fff;
}

.contact-block {
    //padding: 6%;
}

.navbar-default {
    .navbar-collapse, .navbar-form {
        border-color: #3ab1b0;
    }
}

.no-bg {
    background: none;
}

.actie-wrapper {
    p span {
        font-weight: 600;
    }
    h2 {
        color: #d21720;
    }

    img {
        width: 100%;
    }
}

.social-row {
    background-color: #d21720;
    height: 70px;
    position: relative;
    top: 25px;
    display: inline-block;
    img {
        right: -18px;
        position: relative;
        margin-top: 10px;
    }
}

.active-menu {
    background-color: #fff !important;
}

.actie-wrapper a {
    font-size: 16px;
    font-weight: 600;
    color: #d21720;
}

.contact-bedankt h2 {
    width: 100%;
    left: inherit;
    margin-left: 0px;
    font-size: 18px;
    font-weight: 700;
}

.subscribe-actie {
    max-width: inherit !important;
    .part {
        position: relative;
    }
    .part-1 {
        font-size: 14px;
    }
    .part-2 input {
        height: 45px !important;
        width: 100%;
    }
}

.actie .rowf {
    margin-left: 0px;
}

.subscribe-actie .part-2 {
    left: inherit;
}

.bedankt {
    .parsley-required, .parsley-type {
        color: #fff;
        list-style: none;
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 5px;
        font-weight: 700;
    }
}

.contact {
    .social-blok {
        margin-top: 0px;
    }
    .social-icon {
        top: 25px !important;
    }
    .facebook {
        margin-top: 25px;
    }
}

.nieuwsbrief .facebook {
    margin-top: 325px;
}

@media (min-width: 1200px) {
    .bedankt {
        .parsley-required, .parsley-type {
            margin-left: -115px !important;
        }
    }
    .subscribe-actie .part-2 {
        margin-left: -75px !important;
    }
    #navbar {
        height: 110px !important;
    }
}

@media (max-width: 1024px) {
    .subscribe-actie .part-1 {
        font-size: 12px;
    }
    .bedankt .part-3 {
        margin-top: 15px;
        margin-bottom: 0px;
    }
}

@media (max-width: 768px) {

    .contact-header-img, .over-ons-header-img, .hier-te-koop-header-img {
        display: none;
    }

    .tips-header {
        background-image: none;
        background-color: #96d25c;
    }

    .bedankt {
        .part-3 {
            margin-bottom: 15px;
        }
        .part-1 {
            line-height: 40px;
        }
    }

    .product-header {
        display: block !important;
        padding-top: 30px;
        background-color: #24B1BE;
        background-image: url('/uploads/afbeeldingen/productheader_mobile.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: contain !important;
        height: 380px;
    }
}

@media (min-width: 425px) and (max-width: 768px) {
    .Actie .social-icon {
        top: 77px !important;
    }
}

@media (max-width: 425px) {

    .bedankt h1 {
        font-size: 36px !important;
    }
    .Actie .social-icon {
        top: 45px !important;
    }
    .subscribe-actie .part-1 {
        font-size: 11px;
    }
    .bedankt {
        .subscribe-row {
            max-width: inherit;
            margin-left: 15px;
        }
        .rowf {
            margin-left: 0px;
        }
    }

    .box-2-text {
        p {
            margin-top: 20px;
        }
    }

    .product-header {
        background-size: contain !important;
        height: 280px;
    }

    .product-view {
        .image {
            padding: 20px 0 0 10px;
        }
    }

    .product-info-detail img {
        width: 100%;
        margin-left: 0;
    }

    .tips-titel h1 {
        margin-top: -85px;
    }
}

#page-loader {
    display: none;
}

.v-center {
    display: flex;
    align-items: center;
}

.mask-text span {
    height: 323px;
    overflow: hidden;
    display: block;
}

.product-titel h1 {
    line-height: initial;
}

.product-header {
    background-size: cover;
}

.level-2 .box-2 {
    margin-top: 0px;
}

.box-2-2, .box-2-3, .box-2-4 {
    margin-top: -45px !important;
}

.box-2 .box-2-text-hover p {
    color: #fff;
    font-size: 16px;
}

.level-4 {
    position: relative;
    z-index: 99999;

    &.no-zindex {
        z-index: 0;
    }
}

.product-info-detail {
    //height: 415px;
    //padding-top: 75px;
    img {
        max-width: 100%;
    }
}

.videoWrap {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.embedVideo {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.entry-row {
    padding-top: 30px;
}

.video-side {
    padding-top: 25px;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #FF0000;
}

@media (max-width: 992px) {
    .level-2 {
        height: auto;
    }
    .box-2-2:hover .box-2-text-hover p {
        max-height: 263px;
    }
    .level-3 {
        height: auto;
        position: relative;
        z-index: 999;
    }
}

@import "pages/calculator";
@import "assets/language";